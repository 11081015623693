import { buildUpdated } from "stores/features/appBuilder/build";
import { LicenseSupportStatus } from "stores/features/appBuilder/license";
import { CartState } from "stores/features/cart";
import Plugin from "types/Plugin";

import apiSlice from ".";
import { TagType } from "./constants";

export interface ApiApplyLicense {
  error?: string;
}

export interface ApiApplyLicenseArgs {
  privateKey: string;
  licenseKey: string;
}

export interface ApiGetLicense {
  customerEmail: string;
  licenseKey: string;
}

export interface ApiPurchaseLicense {
  error?: string;
  sessionUrl?: string;
}

export interface ApiPurchaseLicenseArgs {
  privateKey?: string;
  cart: Readonly<Partial<CartState>>;
  cartSessionId: string;
  supportLicense: LicenseSupportStatus;
  supportNativePlugins?: Plugin[];
  supportPrice: number;
  supportPushNotifications?: Plugin[];
}

export interface ApiGetAppListing {
  appStoreListing: {
    title: string;
    url: string;
    updated?: string;
    released?: string;
  };
  googlePlayListing: {
    title: string;
    url: string;
    updated?: string;
    released?: string;
  };
}

const licensesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    applyLicense: build.mutation<ApiApplyLicense, ApiApplyLicenseArgs>({
      invalidatesTags: [TagType.AppBuilder, TagType.AppBuilderStatus],
      query: ({ licenseKey, privateKey }) => ({
        url: `license/${licenseKey}`,
        body: { privateKey },
        method: "POST",
      }),
    }),

    getLicense: build.query<ApiGetLicense, string>({
      query: (cartSessionId) => ({
        url: `cart/${cartSessionId}`,
      }),
    }),

    purchaseLicense: build.mutation<ApiPurchaseLicense, ApiPurchaseLicenseArgs>(
      {
        invalidatesTags: [TagType.AppBuilder, TagType.AppBuilderStatus],
        query: ({
          cart,
          cartSessionId,
          privateKey,
          supportLicense,
          supportNativePlugins = [],
          supportPushNotifications = [],
        }) => {
          const skus: string[] = [];

          if (cart.basicLicense) {
            skus.push("app");
          }

          if (cart.assistedFullServiceConfig) {
            skus.push("assistedFullServiceConfig");
          }

          if (cart.appConfigFullService) {
            skus.push("fullServiceConfig");
          }

          if (cart.developerOnboarding) {
            skus.push("developerOnboarding");
          }

          if (cart.virtualOnsite) {
            skus.push("virtualOnsite");
          }

          if (cart.professionalPublishInitial) {
            skus.push("appStorePublishing");
          }

          if (cart.professionalPublishUpdate) {
            skus.push("appStorePublishingUpdate");
          }

          cart.nativePlugins?.forEach((plugin) => {
            skus.push(plugin.gnid);
          });

          cart.pushNotifications?.forEach((pushNotification) => {
            skus.push(pushNotification.gnid);
          });

          const hasPremiumPlugins = [
            ...supportNativePlugins,
            ...supportPushNotifications,
            ...(cart.nativePlugins || []),
          ].some((plugin) => plugin.premiumPlugin);

          if (
            cart.professionalSupport ||
            (supportLicense === "basic" && hasPremiumPlugins)
          ) {
            skus.push(
              hasPremiumPlugins || supportLicense === "appUpdatesPremium"
                ? "samPremium"
                : "samBasic"
            );
          } else if (
            cart.samUpdates ||
            (supportLicense === "appUpdates" && hasPremiumPlugins)
          ) {
            skus.push(hasPremiumPlugins ? "samUpdatesPremium" : "samUpdates");
          }

          return {
            url: privateKey ? `purchase/${privateKey}` : "purchase",
            body: {
              sessionId: cartSessionId,
              skus,
            },
            method: "POST",
          };
        },
      }
    ),
    getAppListing: build.query<ApiGetAppListing, string>({
      providesTags: [TagType.AppBuilder, TagType.AppBuilderStatus],
      query: (privateKey) => ({
        url: `license/store-listing/${privateKey}`,
      }),
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          if (data.appStoreListing) {
            dispatch(
              buildUpdated({
                appListing: {
                  ios: {
                    title: data.appStoreListing.title,
                    url: data.appStoreListing.url,
                    updated: data.appStoreListing.updated,
                    released: data.appStoreListing.released,
                  },
                },
              })
            );
          }

          if (data.googlePlayListing) {
            dispatch(
              buildUpdated({
                appListing: {
                  android: {
                    title: data.googlePlayListing.title,
                    url: data.googlePlayListing.url,
                    updated: data.googlePlayListing.updated,
                    released: data.googlePlayListing.released,
                  },
                },
              })
            );
          }
        } catch (e) {
          // NO-OP
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useApplyLicenseMutation,
  useGetLicenseQuery,
  useGetAppListingQuery,
  usePurchaseLicenseMutation,
} = licensesApi;

export default licensesApi;
