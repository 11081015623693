import { formatISO } from "date-fns";
import { camelize } from "humps";
import { nanoid } from "nanoid";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";
import { useLocalStorage } from "react-use";

import { usePurchaseLicenseMutation } from "services/api/licenses";
import { useListPluginsQuery } from "services/api/plugins";
import { useLazyGetProjectQuery } from "services/api/projects";
import { useAppDispatch, useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";
import {
  cartReset,
  selectCart,
  selectCartPrice,
  selectProfessionalSupportPrice,
} from "stores/features/cart";
import { selectPrivateKey } from "stores/features/project";
import { trackEvent } from "utils/analytics";
import { handleError } from "utils/errors";
import { StorageCart, storageKeys } from "utils/storage";

interface UseCheckout {
  mode?: "appBuilder" | "cart" | "pricing";
}

export const useCheckout = ({ mode = "appBuilder" }: UseCheckout) => {
  const [getProject] = useLazyGetProjectQuery();
  const [purchaseLicense, { isLoading: isCheckingOut }] =
    usePurchaseLicenseMutation();
  const { data: nativePluginsList = [] } = useListPluginsQuery();

  const dispatch = useAppDispatch();
  const cart = useAppSelector(selectCart);
  const cartPrice = useAppSelector((state) =>
    selectCartPrice(state, nativePluginsList)
  );
  const license = useAppSelector(selectLicense);
  const privateKey = useAppSelector(selectPrivateKey);
  const supportPrice = useAppSelector((state) =>
    selectProfessionalSupportPrice(state, nativePluginsList)
  );
  const [storageCart = {}, setStorageCart] = useLocalStorage<StorageCart>(
    storageKeys.cart({ privateKey, mode })
  );

  const router = useRouter();
  const { t } = useTranslation();

  const handleCartCheckout = useCallback(async () => {
    try {
      if (isCheckingOut) return;

      if (mode === "pricing") {
        trackEvent("pricing_flow_go_to_checkout");
      }

      const nativePluginsInstalled = nativePluginsList.filter((plugin) =>
        Object.keys(license.nativePlugins).includes(camelize(plugin.gnid))
      );

      const pushNotificationInstalled = nativePluginsList.filter((plugin) =>
        Object.keys(license.pushNotifications).includes(camelize(plugin.gnid))
      );

      const cartSessionId = nanoid();
      setStorageCart({
        ...storageCart,
        ...cart,
        cartPrice,
        cartSessionId,
        purchasedAt: null,
        supportPrice,
        updatedAt: formatISO(new Date()),
      });

      const { sessionUrl } = await purchaseLicense({
        privateKey: ["appBuilder", "cart"].includes(mode)
          ? privateKey
          : undefined,
        cart,
        cartSessionId,
        supportLicense: license.app.support,
        supportNativePlugins: nativePluginsInstalled,
        supportPushNotifications: pushNotificationInstalled,
        supportPrice,
      }).unwrap();

      if (sessionUrl) {
        await router.push(sessionUrl);
      } else if (privateKey) {
        await getProject({ privateKey, headers: {} }).unwrap();

        dispatch(cartReset());
      }
    } catch (e) {
      handleError(e, { t });
    }
  }, [
    cart,
    cartPrice,
    dispatch,
    getProject,
    isCheckingOut,
    license.app.support,
    license.nativePlugins,
    license.pushNotifications,
    mode,
    nativePluginsList,
    privateKey,
    purchaseLicense,
    router,
    setStorageCart,
    storageCart,
    supportPrice,
    t,
  ]);

  return {
    isCheckingOut,
    onCheckout: handleCartCheckout,
    setStorageCart,
    storageCart,
  };
};
